
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./NavBar";
import { productsData } from "../utils/data/product";
import "./Product.css"; // Import the Product CSS file to access the styles

function Product() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  // Simulated API call to fetch more products
  const fetchProducts = async () => {
    // Simulated delay
    setLoading(true);
    // await new Promise((resolve) => setTimeout(resolve, 1000));

    setTimeout(() => {
      const data = productsData.map((item) => item);

      setProducts(data);
      setLoading(false);
    }, 1000);
  };

  // Load more products when user scrolls to the bottom
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [page]);

  return (
    <div>
      <Navbar className="navbar" />
      <div className="grid-container">
        {products.map((product) => (
          <div className="product-card" key={product?.id}>
            <div className="product-image-list">
              <img
                src={product.blouse.image}
                alt={product.name}
                className="product-img"
              />
              <img
                src={product.pants.image}
                alt={product.name}
                className="product-img"
              />
              <img
                src={product.shoe.image}
                alt={product.name}
                className="product-img"
              />
            </div>
            <div className="card-content">
              <h4>{product?.name}</h4>
              <p>{product?.description}</p>
              <button
                className="button"
                onClick={() => navigate(`/preview/${product?.id}`)}
              >
                View
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="product-scroll-status">
        {loading && <p>Loading...</p>}
      </div>
    </div>
  );
}

export default Product;

import React, { Component } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';

export default class Menus extends Component {
  render() {
    return (
    <>
      <Navbar bg="black" variant="dark" expand="lg" className="justify-content-between">
        <Container fluid style={{ marginLeft: '5rem', marginRight: '5rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
          <Navbar.Brand href="#home" className="mx-auto fs-2"><strong>style-sync</strong></Navbar.Brand>
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <Nav.Link href="/">Home</Nav.Link>
          
            <Nav.Link href="/signin">Signin</Nav.Link>
            
          </Nav>
        </Container>
      </Navbar>
    </>
    );
  }
}



import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation hooks
import "./NavBar.css";

export default function CustomNavbar() {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  // Function to handle logout
  const handleLogout = () => {
    // Perform logout actions here
    console.log("Logout");
    // Redirect user to home page
    navigate('/');
  };

  return (
    <Navbar bg="black" expand="lg" className="justify-content-between">
      <Container fluid style={{ marginLeft: '5rem', marginRight: '5rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <MenuIcon style={{ color: 'white' }} />
          <Nav className="mx-auto fs-2">
            <Nav.Link href="#home"><strong>style-sync</strong></Nav.Link>
          </Nav>
          <Nav className="justify-content-end">
            {location.pathname === '/mix-and-match' || location.pathname.includes('/preview/') || location.pathname.includes('/mix-and-match/') || location.pathname === '/product' ? (
              <button className="btn btn-dark btn-lg custom-logout-button" onClick={handleLogout}>
                Log out
                <div className="custom-chevron"></div>
              </button>
            ) : (
              <>
                <a href="/signin" className="btn btn-dark btn-lg custom-signin-button mr-3">
                  Sign in
                  <div className="custom-chevron"></div>
                </a>
                <a href="/signup" className="btn btn-outline-light btn-lg custom-signup-button group-signup">
                  Sign up
                  <div className="custom-chevron"></div>
                </a>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

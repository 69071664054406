

import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"; // Import your App.css file

import Home from "./pages/Home/Home";
import SignUp from "./components/SignUp.js";
import SignIn from "./components/SignIn.js";
import ResetPassword from "./components/ResetPassword";
import Product from "./components/Product";
import PreviewPage from "./components/PreviewPage"; // Importing the PreviewPage component
import MixAndMatchPage from "./components/MixAndMatchNewPage.js"; // Importing the MixAndMatchPage component
import MixAndMatchNewPage from "./components/MixAndMatchNewPage";

function App() {
  const handleLogout = () => {
    // Perform logout actions here, such as clearing session/local storage or redirecting
    // For example:
    // localStorage.removeItem('token');
    // history.push('/signin'); // Redirect to sign-in page
  };

  return (
    <Router>
      <div className="">
        {" "}
        {/* Apply container styles */}
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/" element={<Home />} />
          <Route path="/forgot-password" element={<ResetPassword />} />
          <Route path="/product" element={<Product />} />
          <Route path="/preview/:id" element={<PreviewPage />} />
          <Route
            path="/mix-and-match/:id"
            element={<MixAndMatchNewPage />}
          />{" "}
          {/* Route for the MixAndMatchPage component */}
          <Route
            path="/logout"
            element={<button onClick={handleLogout}>Logout</button>}
          />{" "}
          {/* Route for the logout button */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;

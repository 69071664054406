import React from "react";

import CustomNavbar from "../../components/NavBar";
import Front from "./components/Front";
import Bottom from "./components/Bottom/bottom";

export default function Home() {
 
  const backgroundColor = "white";

  return (
    <div className={`bg-${backgroundColor} flex flex-col `}>
      <div className="">
        <CustomNavbar />
      </div>
      <Front/>
      <div>
        <Bottom />
      </div>
    </div>
  );
}

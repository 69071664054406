import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./mixAndMatchCarousel.css";

const Carousel = ({ data, onSelectPreview, option }) => {
  return (
    <div
      style={{
        minHeight: "13rem",
        maxWidth: "30rem",
        backgroundColor: "white",
      }}
    >
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        {data?.map((item) => (
          <SwiperSlide key={item.id}>
            <div
              onClick={() => onSelectPreview(item, option)}
              style={{
                maxHeight: "12rem",
                maxWidth: "12rem",
                margin: "0 auto",
                cursor: "pointer",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/${item[option].image}`}
                alt={item[option].name}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
